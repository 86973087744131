.app-system-tabs-solo-hardware {
  display: grid;
  grid-template-columns: repeat(7, 1fr)
}

.app-solo-systems-page {

  .app-select {
    .app-select-trigger-container {
      display: block;
    }

    .app-select-items {
      left: -190px;
      top: 20px;

      .app-select-item {
        text-transform: none;
      }
    }
  }
}
