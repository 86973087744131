@import 'variables';

.app-mp-bold-16 {
  @include bold16;
  letter-spacing: 0;
  text-transform: capitalize;
}

.app-form-section-title {
  @include semi24;
  letter-spacing: 0;
  color: $primary-black-color;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.app-bg-primary-green-color {
  background-color: $primary-green-color;
}

.app-content-toolbar {
  position: relative;
  z-index: $zindex-dropdown;
  box-shadow: 0 1px 1px $border-color;
  height: $toolbarHeight;
  padding: 0 30px;
  min-height: $toolbarHeight;

  .app-toolbar-action-logo > .app-popup-menu > .app-select-items {
    overflow: visible;
    position: fixed;
    left: unset;
    right: 0;
    transform: translate(0);

    &.app-toolbar-popup-lock-left {
      transform: unset;

      .app-select-items {
        right: unset;
        left: 0;
        top: 139px;
      }
    }

    .app-language-label {
      display: flex;
      align-items: center;

      span {
        margin: 0 9px;
      }
    }

    .app-select-items {
      position: fixed;
      left: unset;
      top: 92px;
      right: 100%;
      max-height: unset;
    }
  }
}

.app-text-secondary-red-color {
  color: $required-mark-color;
}

.app-bg-primary-gray-color {
  background-color: $primary-gray-color;
}

.app-bg-primary-white-color {
  background-color: $primary-white-color;
}

.app-d-flex-vertical-100 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-flex-grow-1 {
  flex-grow: 1;
}

.app-flex-vertical-scrollable {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;

  &.app-flex-vertical-scrollable-padding-right {
    padding-right: $scrollPaddingWidth;
  }
}

.app-content {
  z-index: 0;
  background-color: $primary-gray-color;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  .app-content-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .app-table-total {
      display: flex;
      justify-content: flex-end;
      gap: 5px;
      font: normal normal 600 20px/28px 'myriad-pro';
      color: $primary-black-color;
      text-transform: uppercase;
    }
  }

}

.app-text-primary-white-color {
  color: $primary-white-color;
}

.app-text-primary-black-color {
  color: $primary-black-color;
}

.app-icon-with-badge {
  position: relative;

  .app-badge {
    position: absolute;
    @include segoe9;
    min-width: 16px;
    min-height: 16px;
    top: -4px;
    right: -4px;
    background-color: $primary-green-color;
    color: $primary-white-color;
    box-shadow: 0 1px 1px $border-color;
    border-radius: 50%;
    text-align: center;
    line-height: 16px;
  }

}

.app-hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

.app-bold-26 {
  @include bold26;
}

.app-toolbar-breadcrumbs {
  display: flex;
  align-items: center;
  min-width: 0;
  flex-shrink: 1;

  span {
    margin-right: 9px;
  }

  .app-toolbar-breadcrumb {
    @include bold26;
    letter-spacing: 0;
    color: $primary-black-color;
    text-transform: uppercase;
    display: flex;
  }

  .app-toolbar-breadcrumb-separator {
    @include bold26;
    color: $primary-green-color;
    height: 32px;

    &.app-rotate-svg-180 svg {
      transform: rotate(180deg);
      transform-origin: center;
    }
  }
}

.app-toolbar-breadcrumbs-mobile {
  display: none;
}

.app-search {
  position: relative;
  font: normal normal normal 20px/28px 'myriad-pro';
  color: $primary-black-color;
  max-width: 737px;
  width: 50%;
  display: flex;
  align-items: center;

  .app-search-search-icon {
    position: absolute;
    top: 9px;
    left: 12px;
  }

  .app-search-clear-icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  input {
    border-radius: 12px;
    border: none;
    padding: 12px 12px 12px 46px;
    background-color: $primary-white-color;
    box-sizing: border-box;
    width: 100%;
    @include formControlFont;
    color: $primary-black-color;
    height: 44px;

    &::placeholder {
      @include formControlFont;
      color: $primary-black-color;
      opacity: 0.13;
    }

    &:focus {
      outline: none;
    }
  }
}

@mixin buttonShared {
  font: normal normal 600 20px/20px 'myriad-pro';
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  padding: 12px 14px;
  min-width: 194px;
  border-radius: 12px;
  text-align: center;

  @media screen and (max-width: 768px) {
    min-width: unset;
  }

  &[disabled],
  &.disabled {
    cursor: default;
    color: hexToRGBA($primary-white-color, 50%);
    background-color: hexToRGBA($primary-black-color, 50%);
  }
  
  &.disabled-green[disabled],
  &.disabled-green.disabled, 
  &.disabled-green {
    background-color: hexToRGBA($primary-green-color, 50%);
  }
}

.app-secondary-button {
  @include buttonShared;
  border: $primary-green-color 1px solid;
  box-sizing: border-box;
  background: transparent;
  color: $primary-green-color;
}

.app-primary-button {
  @include buttonShared;
  border: none;
  background-color: $primary-green-color;
  color: $primary-white-color;

  &.app-primary-button-browse {
    font-size: 16px;
    padding: 9px;
    width: 114px;
    min-width: 114px;
  }
}

.app-link-primary-button {
  font-weight: 600;
  font-size: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: fit-content;
  color: $primary-green-color;
}

.app-link {
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
}

.app-warn-button {
  @include buttonShared;
  border: none;
  background-color: $secondary-red-color;
  color: $primary-white-color;
}

.app-table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  &.app-table-85 {
    height: 85%;
  }

  .app-table-2-cols {
    display: grid;
    grid-template-columns: 1fr $actionsWidth;
  }

  .app-table-3-cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr) $actionsWidth;
  }

  .app-table-4-cols {
    display: grid;
    grid-template-columns: repeat(3, 1fr) $actionsWidth;
  }

  .app-table-5-cols {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .app-table-6-cols-with-actions {
    display: grid;
    grid-template-columns: repeat(5, 1fr) $actionsWidth;
  }

  .app-table-7-cols {
    display: grid;
    grid-template-columns: repeat(6, 1fr) $actionsWidth;
  }

  .app-table-col-user-status {
    display: flex;
    align-items: center;

    .app-table-col-user-status-text {
      width: 95px;
    }

    .app-table-col-user-status-icon {
      height: 20px;
      display: flex;
      align-items: flex-end;
    }
  }

  .app-table-header-row {
    margin-bottom: 18px;
    align-items: center;

    &.app-table-header-scroll-visible {
      width: calc(100% - $scrollWidth - $scrollPaddingWidth);
      box-sizing: border-box;
    }

    .app-table-header-row-with-sort {
      display: flex;

      &.app-table-bulk-delete {
        .app-select {
          .app-select-items {
            left: -100px;
          }
        }
      }

      .app-select {
        width: fit-content;

        .app-select-items {
          width: fit-content;
          top: 25px;
          left: -26px;
          white-space: nowrap;

          .app-select-item {
            padding: 12px 20px;
            font: normal normal normal 16px/22px 'myriad-pro';
            text-transform: none;

            &[aria-selected="true"] .app-table-col-green-icon {
              circle {
                fill: $primary-white-color;
              }
            }
          }
        }
      }
    }

    font: normal normal 600 19px/28px 'myriad-pro';
    letter-spacing: 0;
    color: $primary-black-color;
    text-transform: uppercase;
    text-align: left;
    padding: 0 15px;
  }

  .app-table-bulk-delete-totals {
    font: normal normal 600 32px/32px 'myriad-pro';
    text-transform: uppercase;
  }

  .app-table-content-row {
    background-color: $primary-white-color;
    border-radius: 12px;
    font: normal normal normal 18px/28px 'myriad-pro';

    .app-table-action-icon {
      &:hover {
        cursor: pointer;
        filter: $img-primary-white-filter;
      }
    }

    .app-table-bulk-delete-icon {
      height: 34px;

      &:hover {
        cursor: pointer;
      }
    }

    &:not(:first-child) {
      margin-top: 20px;
    }

    padding: 15px 15px;

    &:not([data-hover-disabled="true"]) {
      cursor: pointer;

      &:hover {
        background-color: $primary-green-color;
        color: white;

        .app-payment-overview-status-text{
          color: white !important;
        }

        .app-table-action-icon {
          filter: $img-primary-white-filter;
        }

        .app-table-col-green-icon {
          circle {
            fill: $primary-white-color;
          }
        }
      }
    }

    > div {
      padding-right: 10px;
    }
  }
}

.app-form-control-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  width: 100%;
  gap: 20px;

  @media screen and (max-width: 870px) {
    flex-direction: column;
    max-width: 512px;
  }

  @media screen and (max-width: $zoomApplied) {
    max-width: 100%;
  }
}

.app-blurred-image {
  filter: blur(7px);
}

.app-form-control {
  margin-bottom: 23px;
  position: relative;

  .app-form-error {
    color: $secondary-red-color;
    padding-left: 22px;
    font: normal normal normal 16px/16px 'myriad-pro';
    display: inline-block;

    &.app-form-error-with-prefix {
      padding-left: 87px;

      @media screen and (max-width: 800px) {
        padding-left: 67px;
      }
    }
  }

  .app-form-control-hint {
    color: $secondary-red-color;
    padding-left: 5px;
    margin-top: 5px;
    font: italic normal normal 14px/21px 'myriad-pro';
    display: inline-block;
    font-style: italic;
    color: $secondary-gray-color;
  }

  .app-form-control-label {
    font: normal normal 600 20px/28px 'myriad-pro';
    letter-spacing: 0;
    padding-left: 21px;
    color: $primary-black-color;
    margin-bottom: 10px;

    @media screen and (max-width: 800px) {
      font-size: 17px;
    }

    &.app-form-control-label-with-prefix {
      padding-left: 85px;

      @media screen and (max-width: 800px) {
        padding-left: 65px;
      }
    }
  }

  .app-form-control-input-link {
    font: normal normal normal 16px/22px 'myriad-pro';
    text-decoration: underline;
    color: $primary-green-color;
    margin-top: 15px;
    cursor: pointer;

    &.app-form-control-input-link-with-prefix {
      padding-left: 69px;
    }
  }

  .app-form-control-input {
    display: flex;
    align-items: center;
    position: relative;

    .app-form-control-prefix {
      margin-right: 40px;
      font-size: 25px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 800px) {
        margin-right: 20px;
      }

      svg {
        width: 25px;
        height: 25px;
      }

      img {
        width: 25px;
        height: 25px;
      }
    }

    .app-post-input-content {
      position: absolute;
      right: -40px;
      top: 10px;
    }

    .app-form-control-postfix {
      position: absolute;
      right: 15px;
      cursor: pointer;
      height: fit-content;

      &.app-form-control-postfix-eye {
        top: 10px;
      }
    }

    .app-form-input-postfix-switch {
      position: absolute;
      right: 12px;
      top: 8px;
    }

    .app-form-input-postfix-button {
      position: absolute;
      right: 0;
    }

    input[type=text],
    input[type=password] {
      border-radius: 12px;
      border: none;
      padding: 15px 20px;
      background-color: $primary-white-color;
      box-sizing: border-box;
      width: 100%;
      @include formControlFont;
      color: $primary-black-color;
      height: 44px;

      &::placeholder {
        @include formControlFont;
        color: $primary-black-color;
        opacity: 0.13;
      }

      &:disabled {
        cursor: default;
        opacity: 0.6;
      }

      &:read-only {
        &:focus {
          border: var(--border-size) solid $border-color !important;
          box-shadow: none !important;
          filter: none;
        }

        &:hover {
          cursor: default;
        }
      }

      &:focus {
        outline: none;
        @include formControlFocused;
      }

      &.app-form-input-outlined {
        box-shadow: 0 1px 1px $border-color;
        border: 0.5px solid $primary-black-color;

        &::placeholder {
          opacity: 0.18;
        }

        &:focus {
          outline: none;
          @include formControlFocused;
        }
      }

      &::-ms-clear,
      &::-ms-reveal {
        display: none !important;
      }
    }
  }

  *[aria-invalid="true"],
  &[aria-invalid="true"] {
    .app-form-select .app__control.app__control--is-focused {
      border: none;
    }

    .app-form-select,
    input[type=text],
    input[type=text]:focus,
    input[type=text].app-form-input-outlined,
    input[type=text].app-form-input-outlined:focus,
    input[type=password],
    input[type=password]:focus,
    input[type=password].app-form-input-outlined,
    input[type=password].app-form-input-outlined:focus {
      border: 0.5px solid $secondary-red-color;
      outline: none;
   }
  }

  &[aria-disabled="true"],
  &[aria-disabled],
  *[aria-disabled="true"],
  *[aria-disabled] {
    opacity: 0.6;
  }
}

.app-navigation-link {
  border-radius: 12px;
  color: $primary-black-color;
  text-decoration: none;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 6px;
  }

  &:not(.app-navigation-link-active):hover {
    &:not(.app-hover-disabled) {
      @include link-hover;
      img {
        filter: $img-primary-green-filter
      }
    }
  }

  &.app-navigation-link-active {
    @include link-active;

    img {
      filter: $img-primary-white-filter
    }
  }
}

.app-tabs {
  background-color: $primary-black-color;
  display: flex;
  align-items: center;

  .app-tab {
    font: normal normal normal 16px/22px 'myriad-pro';
    letter-spacing: 0;
    color: $primary-white-color;
    text-decoration: none;
    text-transform: capitalize;
    opacity: 1;
    text-align: center;
    width: 150px;
    padding: 12px 0;

    &.app-tab-active {
      font: normal normal 600 18px/26px 'myriad-pro';
      border-bottom: 3px solid $primary-green-color;
      box-sizing: border-box;
    }
  }
}

.app-popup-container {
  position: absolute;
  z-index: $zindex-popover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(136, 136, 136, .6);
}

.app-popup-info {
  font-family: 'myriad-pro', serif;
  color: $primary-black-color;
  font-size: 20px;
  max-width: 80%;
  text-align: center;
}

.app-popup-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  .app-popup-form-controls {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 45px 0 45px;
    height: 100%;

    .app-popup-form-control {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &[aria-invalid="true"] {
        .app-popup-form-control-input {

          .app-popup-form-input,
          .app-popup-form-input:focus {
            border: .5px solid $secondary-red-color;
          }
        }
      }

      .app-popup-form-control-label {
        padding: 0 20px;
        font: normal normal 600 20px/28px 'myriad-pro';
        color: $primary-black-color;

        &.app-no-padding {
          padding: 0;
        }
      }

      .app-popup-form-error {
        color: $secondary-red-color;
        padding-left: 20px;
        font: normal normal normal 16px/16px 'myriad-pro';
      }

      .app-popup-form-control-input {
        .app-popup-form-input {
          height: 50px;
          padding: 15px 25px;
          box-sizing: border-box;
          min-width: 60%;
          box-shadow: 0 1px 1px #00000029;
          border: .5px solid $primary-black-color;
          border-radius: 12px;
          font-size: 20px;
          color: $primary-black-color;

          &:focus {
            outline: none;
            border: 1px solid $primary-green-color;
          }
        }
      }
    }
  }
}

.app-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  max-width: 927px;
  height: 508px;
  max-height: 95%;
  border-radius: 24px;
  position: relative;
  background: white url('./assets/icons/Modal icons/modal-background.svg') center center no-repeat;
  background-size: cover;
  box-shadow: 0 3px 6px #00000029;
  margin: 0 10px;

  &.app-remove-devices-popup {
    height: 557px;
  }

  &.app-popup-without-title {
    .app-popup-buttons {
      .app-popup-right-button {
        right: 0;
      }

      .app-popup-left-button {
        left: 0;
      }
    }

  }

  &.app-popup-worldpay{
    height: 95%;
    padding: 10px 0;
    gap: 20px;

    .app-popup-body {
      height: 90%;
    }

    .app-popup-buttons {
      display: block;
      padding-bottom: 80px;
    }
  }

  .app-popup-body{
    box-sizing: border-box;
    overflow: auto;
    >span{
      padding: 50px 30px 0 30px;
    }
    >svg{
      padding-top: 45px;
    }
  }

  &.app-popup-eula {
    width: 80%;
    gap: 20px;
  }

  &.app-popup-connecting {
    width: 65%;
    gap: 90px;
  }

  &.app-help-popup-content {
    width: 70%;
    max-width: 70%;
    height: 80%;
    color: $primary-black-color;
    padding: 35px 0 0 0;
    gap: 30px;

    .app-help-popup-title {
      color: $primary-green-color;
    }

    .app-help-popup-content-header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 35px;
      align-self: flex-start;
    }

    .app-single-video-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      width: 100%;

      .app-single-video {
        flex: 1;
        width: 80%;
        border-radius: 25px;
      }
    }
  }

  .app-popup-info-container {
    display: flex;
    flex-direction: column;
    gap: 35px;
    align-items: center;
  }

  .app-popup-header {
    font: normal normal 600 20px/20px 'myriad-pro';
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 25px 30px;
    box-sizing: border-box;

    .app-popup-line {
      position: absolute;
      border-bottom: 1px solid $primary-gray-color;
      width: 100%;
      left: 0;
      bottom: 0;
    }

    .app-popup-title {
      flex-grow: 1;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0;
      color: $primary-black-color;
    }

    .app-popup-header-close {
      float: right;
      padding-left: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .app-popup-buttons {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    height: 40px;
    flex-shrink: 0;
    padding-bottom: 50px;

    .app-popup-left-button {
      position: absolute;
      border: none;
      border-radius: 0 9px 9px 0;
      background-color: #e4e4e4;
      color: $primary-black-color;
      opacity: .9;
    }

    .app-popup-right-button {
      position: absolute;
      right: 0;
      border: none;
      border-radius: 9px 0 0 9px;


      &[disabled] {
        background-color: #b1b1b0;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: $popupButtonsChanged) {
  .app-popup-content {

    &.app-help-popup-content {
      zoom: 0.7;
    }

    &.app-help-popup-content,
    &.app-popup-eula,
    &.app-popup-delete-user,
    &.app-popup-delete-system,
    &.app-popup-system-added,
    &.app-popup-connect,
    &.app-popup-connecting {
      width: auto;
    }

    .app-popup-buttons {
      button {
        min-width: 25% !important;
      }
    }

    .app-popup-form-input{
      width: 100%;
    }
  }
}

.app-select {
  width: 100%;
  position: relative;

  &.app-select-with-prefix {
    input {
      text-indent: 37px;
    }

    .app-form-input-prefix {
      position: absolute;
      left: 20px;
      top: 9px;
    }
  }

  .app-select-trigger-container {
    display: inline-block;
    cursor: pointer;
  }

  input[type=text]:read-only,
  input[type=password]:disabled {
    cursor: pointer;
  }

  .app-select-dropdown-icon {
    position: absolute;
    top: 45%;
    right: 20px;
    cursor: pointer;
  }

  &.app-select-not-in-view {
    .app-select-items {
      top: -203px;
    }
  }

  .app-select-items {
    border-radius: 12px;
    z-index: $zindex-dropdown;
    background-color: $primary-white-color;
    width: 100%;
    display: inline-block;
    @include formControlFont;
    color: $primary-black-color;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 45px;
    box-shadow: 0 1px 1px $border-color;
    border: 1px solid $primary-gray-color;


    .app-select-item {
      border-radius: 12px;
      @include formControlFont;
      color: $primary-black-color;
      text-decoration: none;
      box-sizing: border-box;
      cursor: pointer;
      padding: 8px 12px;
      display: flex;
      align-items: center;

      span {
        pointer-events: none;
      }

      .app-select-item-option-prefix {
        margin-right: 8px;
      }

      &[aria-selected]:not([aria-selected="true"]):hover {
        @include link-hover;
      }

      &[aria-selected="true"] {
        @include link-active;
      }
    }
  }

  &.app-popup-menu {
    .app-select-items {
      width: fit-content;
      white-space: nowrap;

      .app-select-item {
        padding: 12px 20px;
      }
    }
  }

  &.app-popup-menu-hidden {
    .app-select-items {
      display: none;
    }
  }
}

.app-delete-system-popup-title {
  padding: 0 30px;
  font: normal normal normal 20px/28px 'myriad-pro';
}

.app-welcome {
  .app-welcome-logo {
    width: 311px;
    height: 74px;
  }

  .app-language-label {
    display: flex;

    svg, img {
      margin-right: 12px;
      width: 34px;
      height: 24px;
    }

    span {
      font: normal normal normal 15px/21px 'myriad-pro';
    }
  }

  .app-select {
    position: fixed;
    top: 0;
    right: 0;
    padding: 8px 17px;
    width: fit-content;

    .app-select-trigger-container {
      display: flex;
      align-items: center;

      .app-language-label {
        margin-right: 18px;
      }
    }

    .app-select-items {
      width: calc(100% + 11px);
      border: none;
      padding: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      top: 41px;
      left: -13px;

      .app-select-item {
        padding: 8px 17px;
        border-radius: 0;
        font: normal normal normal 15px/21px 'myriad-pro';

        &[aria-selected="true"] {
          background: #56AA1C23;
          color: $primary-black-color;
        }
      }
    }
  }

  .app-welcome-to {
    font: normal normal normal 30px/43px 'myriad-pro';
    color: $primary-black-color;
  }

  .app-primary-button,
  .app-secondary-button {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 373px;
    font: normal normal 600 20px 'myriad-pro';
    text-transform: none;
    text-align: center;
    height: 50px;
  }

  @media only screen and (max-width: 1002px) {
    align-items: center;
    justify-content: center;
    height: 100vh;

    .app-welcome-logo {
      width: 267px;
      height: auto;
    }

    & > img {
      display: none;
    }

  }

  @media only screen and (max-width: 382px) {
    padding: 0 17px;

    & > div {
      width: 100%;

      .app-select,
      .app-primary-button,
      .app-secondary-button {
        width: calc(100% - 34px);
      }
    }
  }
}

.app-label-with-checkbox {
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: flex-end;

  .app-checkbox {
    -webkit-appearance: none;
    /*hides the default checkbox*/
    width: 28px;
    height: 28px;
    box-shadow: 0 1px 1px #00000029;
    border: 1px solid #707070;
    border-radius: 2px;

    &:checked:before {
      content: "✔";
      color: $primary-green-color;
      font-size: 20px;
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }

  .app-checkbox-text {
    cursor: pointer;
    font: normal normal normal 20px/27px 'myriad-pro';
  }
}


.app-password-dynamic-validation {
  text-align: left;
  font: normal normal 600 18px/26px 'myriad-pro';
  color: $primary-black-color;
  padding-top: 40px;
  width: 397px;

  p {
    margin-top: 0;
    margin-bottom: 18px;
  }

  &.app-password-dynamic-validation-dirty {
    .app-done:after {
      display: inline-block;
    }

    .app-not-done:after {
      display: inline-block;
    }
  }

  .app-done:after {
    content: '';
    display: none;
    width: 22px;
    height: 12px;
    background: url('./assets/icons/Tick Valid icon.svg') 0 0 no-repeat;
    margin-left: 10px;
  }

  .app-not-done:after {
    content: '';
    display: none;
    width: 11px;
    height: 11px;
    background: url('./assets/icons/Cross invalid icon.svg') 0 0 no-repeat;
    margin-left: 10px;
  }
}

.app-notifications {
  position: absolute;
  top: 40px;
  z-index: $zindex-dropdown;
  right: 80px;
  background-color: $primary-white-color;
  border-radius: 15px;
  box-shadow: 0 3px 6px #00000029;
  width: 345px;

  @media only screen and (max-width: 460px) {
    & {
      right: -20px;
      width: 100vw;
    }
  }

  .app-notifications-header {
    padding: 15px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    .app-notifications-header-title {
      .app-notifications-title {
        font: normal normal 600 14px/21px 'myriad-pro';
        text-transform: uppercase;
      }
    }

    .app-notifications-mark-all-read {
      text-decoration: underline;
      font: normal normal normal 12px/13px 'myriad-pro';
      color: #2680EB;
      cursor: pointer;


      &[aria-disabled="true"] {
        color: hexToRGBA($or-font-color, 0.38);
        cursor: not-allowed;
      }
    }
  }

  .app-notifications-body {
    width: 100%;
    box-sizing: border-box;
    max-height: 612px;
    min-height: 365px;
    overflow-y: auto;

    .app-notifications-empty {
      height: 365px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 24px;

      span {
        font-weight: 600;
        text-transform: capitalize;
        font-size: 17px;
      }
    }

    .app-show-loading-image {
      padding: 20px;
      text-align: center;
      left: 20%;
      position: relative;
    }

    .app-notification {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 15px 13px;
      box-sizing: border-box;

      &.app-notification-active {
        background-color: hexToRGBA(#56AA1C, 0.15);
      }

      &:last-child {
        border-bottom: none;
      }

      .app-notification-hr {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        div {
          transform: translateY(-15px);
          width: calc(100% - 21px);
          height: 0;
          border-bottom: 1px solid $primary-gray-color;
        }
      }

      .app-notification-content {
        padding: 14px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        box-shadow: 0 1px 4px #00000029;
        flex-grow: 1;
        background-color: $primary-white-color;

        &:hover {
          cursor: pointer;
        }

        .app-notification-content-inner {
          width: 200px;
        }

        .app-notification-icon {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 17px;

          img {
            width: 52px;
            height: 52px;
          }

          svg {
            path {
              fill: $primary-white-color;
            }
          }
        }

        .app-notification-title {
          text-transform: uppercase;
          font: normal normal 600 12px/16px 'myriad-pro';
        }

        .app-notification-link {
          font: normal normal normal 12px/16px 'myriad-pro';
          text-decoration: underline;
          cursor: pointer;
          color: $link-color;
        }

        .app-notification-description {
          font: normal normal normal 12px/14px 'myriad-pro';
        }

        .app-notification-created-at {
          font: normal normal normal 10px/15px 'myriad-pro';
        }

        &.app-notification-ok {
          border-left: 7px solid $primary-green-color;

          .app-notification-icon {
            background: $primary-green-color;
          }
        }

        &.app-notification-error {
          border-left: 7px solid $secondary-red-color;

          .app-notification-icon {
            background: $secondary-red-color;
          }
        }

        &.app-notification-warn {
          border-left: 7px solid $secondary-orange-color;

          .app-notification-icon {
            background: $secondary-orange-color;
          }
        }
      }
    }
  }
}

.app-help-video-items {
  padding: 0 51px;
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  flex: 1;

  .app-help-video-item {
    display: flex;
    flex-direction: column;
    gap: 10px;

    iframe {
      border: $primary-green-color 2px solid;
      border-radius: 26px;
      pointer-events: none;
    }

    .app-help-video-item-title {
      text-decoration: underline;
      font: normal normal normal 14px/21px 'myriad-pro';
      color: #2E6D96;
      cursor: pointer;
      text-align: center;
    }
  }
}

.app-tooltip {
  width: fit-content;
  position: relative;
  cursor: pointer;
}

.app-tooltip-body {
  position: absolute;
  z-index: $zindex-dropdown;
  background: $primary-white-color;
  padding: 17px 17px 0;
  border-radius: 17px;
  box-shadow: 0 1px 1px #00000029;
  width: 25vw;
  max-width: 25vw;
  animation: fadeInAnimation ease 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  .app-tooltip-cross {
    position: absolute;
    right: 20px;
  }
}

@media only screen and (max-width: 825px) {
  .app-tooltip-body{
    max-width: 50vw;
    width: 50vw;
  }
}


.app-whitespace-no-wrap {
  white-space: nowrap;
}

.app-form-control-mobile-number {
  .app-form-control-input {
    display: flex;
    flex-wrap: wrap;
    padding-left: 65px;
    gap: 9px;

    @media screen and (max-width: $menuChangedPx) {
      padding-left: 45px;
    }


    > .app-form-control-prefix {
      margin-left: -65px;

      @media screen and (max-width: $menuChangedPx) {
        margin-left: -45px;
      }
    }
  }

  .app-select-dialling-code {
    min-width: 125px;
    flex: 1 1 125px;
    margin-left: -9px;
    :hover{
      cursor: pointer;
    }
  }

  .app-form-control-input-wrapper {
    flex: 10 1 140px;
  }

  .app-select {

    .app-form-input-prefix,
    .app-select-item {
      img {
        width: 34px !important;
        height: 24px !important;
      }
    }

    .app-select-item {
      padding: 10px 20px;
    }
  }
}

.app-paper {
  background-color: $primary-white-color;
  border-radius: 27px;
  box-shadow: 0 1px 1px #00000029;
  padding: 23px 35px;

  &.app-paper-form {
    border-radius: 12px;
    padding: 20px 23px;
    border: 1px solid #707070;
  }

  .app-paper-title {
    color: $primary-black-color;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }

  .app-paper-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .app-paper-footer-text {
    text-align: center;
    display: block;
  }
}

.app-form-control-country {

  .app-form-input-prefix,
  .app-select-item {
    img {
      width: 34px !important;
      height: 24px !important;
    }
  }

  .app-select {
    .app-select-item {
      padding: 10px 20px;
    }
  }
}

.app-signup-logo {
  width: 311px;
  height: 74px;
  max-width: 378px;
}

.app-tooltip-bold-heading {
  color: $primary-green-color;
  font-weight: 600;
  font-size: 16px;
}

.app-border-none {
  border: none !important;
}

.app-vat-number-input {
  .app-form-input {
    outline: 1px $primary-black-color solid;
  }
}
.app-input-required {
  font-size: 125%;
  color: $required-mark-color;
}

*[aria-disabled="true"] {
  opacity: 0.6;
}

.app-text-tooltip-trigger:hover{
  cursor: pointer;
  color: $primary-green-color;
}

.app-loading-text{
  position: absolute;
  display: block;
  top: 53%;
  left: calc(50% - 110px);
  text-align: center;
}

.app-overflow-scroll{
  overflow-y: scroll;
}

.app-table-header-full{
  display: block;
}

.app-table-header-limited{
  display: none;
}

@media screen and (max-width: $menuChangedPx) {
  .app-table-header-full{
    display: none;
  }

  .app-table-header-limited{
    display: block;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
