@import '../../../variables.scss';

.app-stripe-icons {
  border: 1px solid $primary-green-color;
  padding: 20px 8px;
  border-radius: 12px;
  background: white;

  img {
    height: 65px;
  }

  &.app-stripe-icons-small {
    img {
      height: 25px;
    }
  }
}
