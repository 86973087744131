@import '../../variables.scss';

.app-user-solo-form {
  .app-form-section-title {
    font-size: 22px;
  }

  .app-form-control .app-form-control-input input[type=text]:disabled {
    background: $solo-disabled-input-color;
    color: $solo-disabled-input-text;
  }

  .app-form-control .app-form-control-label {
    font-size: 18px;
  }

  .app-form-control .app-form-control-input input[type=text] {
    font-size: 18px;
  }
}
