@import '../../variables';

.site-costs-header {
  padding: 33px 42px 10px 12px;
  display: flex;
  justify-content: end;
  gap: 10px;
}

.site-costs-container{
  height: 100%;
  padding: 0 42px 49px 12px;

  .site-costs-right{
    padding: 16px;
  }
  .site-costs-left{
    padding:16px
  }
}

.costsTableHeader,.costs {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 20px;
}

@media screen and (max-width: 1225px) {
  .site-costs-container {
    flex-direction: column;
    padding: 12px 12px 55px 12px;

    .site-costs-right{
      padding-bottom: 60px;
    }
  }
}
