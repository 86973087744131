@import '../../variables.scss';

.app-table-system-tabs-entry-users {
  display: grid;
  grid-template-columns: 1fr 2fr 1.5fr 1fr 1fr 0.5fr $actionsWidth;
}

.app-table-system-tabs-solo-users {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
